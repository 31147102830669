import React, { useContext } from 'react';
import { Box, Button, Container, Tab, Tabs } from '@mui/material';
import Layout from '../components/Layouts/Layout';
import { TabContext, TabPanel } from '@mui/lab';
import { useState } from 'react';
import ClientCreation from '../components/ClientCreation/ClientCreation';
import ClientList from '../components/ClientList/ClientList';
import ServiceList from '../components/ServiceList/ServiceList';
import ServiceCreation from '../components/ServiceCreation/ServiceCreation';
import InvoiceCreation from '../components/InvoiceCreation/InvoiceCreation';
import InvoiceList from '../components/InvoiceList/InvoiceList';
import PermissoesContext from '../context/PermisoesContext';
const Cadastros = () => {
  const [tabValue, setTabValue] = useState('clients');
  const [clientAdd, setClientAdd] = useState(false);
  const [serviceAdd, setServiceAdd] = useState(false);
  const [invoiceAdd, setInvoiceAdd] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [invoiceData, setInvoiceData] = useState(null);
  const { permissoes } = useContext(PermissoesContext);
  const clientsPermission = permissoes.registrations.clients;
  const servicesPermission = permissoes.registrations.services;
  const invoicesPermission = permissoes.registrations.invoices;

  return (
    <Layout active={'cadastros'}>
      <Container className="p-6">
        <p className="text-start text-xl font-medium tracking-tight pb-6">Cadastros</p>
        <TabContext value={tabValue}>
          <Box className="bg-white rounded-lg shadow px-2">
            <Tabs
              value={tabValue}
              onChange={(_, value) => {
                setTabValue(value);
              }}
            >
              {clientsPermission.read && <Tab label="Clientes" value="clients" />}
              {servicesPermission.read && <Tab label="Serviços" value="services" />}
              {invoicesPermission.read && <Tab label="Faturas" value="invoices" />}
            </Tabs>
          </Box>

          <TabPanel value="clients" sx={{ p: 0 }}>
            <div className="py-4">
              {clientsPermission.create || clientsPermission.edit ? (
                clientAdd ? (
                  <Button
                    color={clientAdd ? 'secondary' : 'primary'}
                    onClick={() => {
                      setClientAdd(!clientAdd);
                      setClientData([]);
                    }}
                    variant={clientAdd ? 'outlined' : 'contained'}
                  >
                    {clientAdd ? 'Voltar' : 'Adicionar'}
                  </Button>
                ) : (
                  <Button
                    color={clientAdd ? 'secondary' : 'primary'}
                    onClick={() => {
                      setClientAdd(!clientAdd);
                      setClientData([]);
                    }}
                    variant={clientAdd ? 'outlined' : 'contained'}
                  >
                    {clientAdd ? 'Voltar' : 'Adicionar'}
                  </Button>
                )
              ) : null}
            </div>
            {clientsPermission.read ? (
              clientAdd ? (
                <ClientCreation client={clientData} setClientAdd={setClientAdd} />
              ) : (
                <ClientList selectedClient={setClientData} setClientAdd={setClientAdd} />
              )
            ) : null}
          </TabPanel>
          <TabPanel value="services" sx={{ p: 0 }}>
            <div className="py-4">
              {servicesPermission.create || servicesPermission.edit ? (
                serviceAdd ? (
                  <Button
                    color={serviceAdd ? 'secondary' : 'primary'}
                    onClick={() => {
                      setServiceAdd(!serviceAdd);
                      setServiceData([]);
                    }}
                    variant={serviceAdd ? 'outlined' : 'contained'}
                  >
                    {serviceAdd ? 'Voltar' : 'Adicionar'}
                  </Button>
                ) : (
                  <Button
                    color={serviceAdd ? 'secondary' : 'primary'}
                    onClick={() => {
                      setServiceAdd(!serviceAdd);
                      setServiceData([]);
                    }}
                    variant={serviceAdd ? 'outlined' : 'contained'}
                  >
                    {serviceAdd ? 'Voltar' : 'Adicionar'}
                  </Button>
                )
              ) : null}
            </div>
            {serviceAdd ? <ServiceCreation service={serviceData} setServiceAdd={setServiceAdd} /> : <ServiceList selectedService={setServiceData} setServiceAdd={setServiceAdd} />}
          </TabPanel>
          <TabPanel value={'invoices'} sx={{ p: 0 }}>
            <div className="py-4">
              {invoicesPermission.create && (
                <Button
                  color={invoiceAdd ? 'secondary' : 'primary'}
                  onClick={() => {
                    setInvoiceAdd(!invoiceAdd);
                    setInvoiceData(null);
                  }}
                  variant={invoiceAdd ? 'outlined' : 'contained'}
                >
                  {invoiceAdd ? 'Voltar' : 'Adicionar'}
                </Button>
              )}
            </div>
            {invoiceAdd ? (
              <InvoiceCreation invoiceData={invoiceData} setInvoiceAdd={setInvoiceAdd} />
            ) : (
              <InvoiceList selectedInvoice={setInvoiceData} setInvoiceAdd={setInvoiceAdd} />
            )}
          </TabPanel>
        </TabContext>
      </Container>
    </Layout>
  );
};

export default Cadastros;
