/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AxiosConfigs from '../../configs';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import PermissoesContext from '../../context/PermisoesContext';

const InvoiceList = ({ selectedInvoice, setInvoiceAdd }) => {
  const [invoices, setInvoices] = useState([]);
  const { permissoes } = useContext(PermissoesContext);
  const invoicesPermission = permissoes.registrations.invoices;
  const chipColor = (state) => {
    if (state === 'paid') return ['success', 'Emitido'];
    if (state === 'pending') return ['warning', 'Em digitação'];
    if (state === 'refunded') return ['secondary', 'Cancelado'];
  };

  const downloadInvoice = (id, name) => {
    AxiosConfigs.post(
      '/invoices/download/' + id,
      {},
      {
        responseType: 'blob',
      }
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', name + '.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  useEffect(() => {
    AxiosConfigs.get('/invoices').then((invoices) => setInvoices(invoices.data.data));
  }, []);

  return (
    <Paper>
      <TableContainer
        sx={{
          maxHeight: 700,
        }}
      >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>Código</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Cliente</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Data de emissão</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow
                key={invoice.id}
                className={`${invoice.state !== 'paid' && invoice.state !== 'refunded' ? 'cursor-pointer' : ''} hover:bg-blue-50`}
                onClick={() => {
                  if (invoice.state !== 'paid' && invoice.state !== 'refunded') {
                    selectedInvoice(invoice);
                    setInvoiceAdd(true);
                  }
                }}
              >
                <TableCell>{invoice.serial_number}</TableCell>
                <TableCell>
                  <Chip size="small" color={chipColor(invoice.state)[0]} label={<Typography variant="caption">{chipColor(invoice.state)[1]}</Typography>} />
                </TableCell>
                <TableCell className="w-4/12 max-w-xs text-nowrap text-ellipsis overflow-hidden">{invoice.client.razao_social}</TableCell>
                <TableCell>{dayjs(invoice.created).format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  {invoicesPermission.read && (
                    <div hidden={invoice.state !== 'paid' && invoice.state !== 'refunded'}>
                      <IconButton
                        onClick={() => {
                          downloadInvoice(invoice.id, invoice.serial_number);
                        }}
                      >
                        <ArrowDownTrayIcon className="size-3.5" />
                      </IconButton>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default InvoiceList;
