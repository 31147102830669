/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { UsuarioContext } from '../../routes/Users.jsx';
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from '@mui/material';

const defaultPermissions = {
  vault: { view: false, create: false, update: false, delete: false },
  config: { view: false, update: false },
  table: { view: false, create: false, update: false, delete: false },
  evidences: { view: false, create: false, update: false, delete: false },
  inventory: { view: false, create: false, update: false, delete: false },
  reports: { view: false, create: false, update: false, delete: false },
  users: { view: false, create: false, update: false, delete: false },
};

const rolesPermissions = {
  admin: {
    vault: { view: true, create: true, update: true, delete: true },
    config: { view: true, update: true },
    table: { view: true, create: true, update: true, delete: true },
    evidences: { view: true, create: true, update: true, delete: true },
    inventory: { view: true, create: true, update: true, delete: true },
    reports: { view: true, create: true, update: true, delete: true },
    users: { view: true, create: true, update: true, delete: true },
  },
  user: {
    vault: { view: true, create: false, update: false, delete: false },
    config: { view: true, update: false },
    table: { view: true, create: false, update: false, delete: false },
    evidences: { view: false, create: false, update: false, delete: false },
    inventory: { view: false, create: false, update: false, delete: false },
    reports: { view: true, create: false, update: false, delete: false },
    users: { view: true, create: false, update: false, delete: false },
  },
  'group-admin': {
    vault: { view: true, create: true, update: true, delete: true },
    config: { view: true, update: false },
    table: { view: true, create: false, update: false, delete: false },
    evidences: { view: true, create: true, update: false, delete: false },
    inventory: { view: true, create: true, update: true, delete: false },
    reports: { view: false, create: false, update: false, delete: false },
    users: { view: true, create: true, update: true, delete: true },
  },
  evidencer: {
    vault: { view: false, create: false, update: false, delete: false },
    config: { view: true, update: false },
    table: { view: false, create: false, update: false, delete: false },
    evidences: { view: true, create: true, update: true, delete: false },
    inventory: { view: false, create: false, update: false, delete: false },
    reports: { view: false, create: false, update: false, delete: false },
    users: { view: false, create: false, update: false, delete: false },
  },
  none: defaultPermissions,
};

const permissionLabels = {
  view: 'Ver',
  create: 'Criar',
  update: 'Editar',
  delete: 'Excluir',
};

const menuLabels = {
  vault: 'Cofre',
  config: 'Configurações',
  table: 'Acronis',
  evidences: 'Evidencias',
  inventory: 'Inventário',
  reports: 'Relatórios',
  users: 'Usuários',
};

const PermissionRow = ({ section, permissions, onChange }) => (
  <div className="grid grid-cols-12 h-8 bg-white">
    <div className="p-2 col-start-1">
      <p>{menuLabels[section]}:</p>
    </div>
    <div className="flex flex-row col-start-3">
      {Object.keys(permissions[section]).map((permType) => (
        <FormGroup key={permType}>
          <div className="flex col-start-4 p-2 ml-6">
            <FormControlLabel
              control={<Checkbox checked={permissions[section][permType] || false} id={`checkbox-${section}-${permType}`} onChange={() => onChange(section, permType)} />}
              label={permissionLabels[permType]}
            />
            {/* <label className="hover:cursor-pointer ml-2" htmlFor={`checkbox-${section}-${permType}`}>
            {permissionLabels[permType]}
          </label> */}
          </div>
        </FormGroup>
      ))}
    </div>
  </div>
);

const PermissionsTab = ({ tenant, tenantToEdit, switchCofre, nameTenantPermissions, setselectStatus, edit }) => {
  const { novoUsuario, setNovoUsuario } = useContext(UsuarioContext);
  const tenantId = tenantToEdit ?? tenant.id;
  const [permissions, setPermissions] = useState(defaultPermissions);

  useEffect(() => {
    const userPermissions = switchCofre
      ? novoUsuario.clients.find((t) => t.client === tenantToEdit)?.permissions
      : novoUsuario.tenants.find((t) => t.tenant === tenantToEdit)?.permissions;

    setPermissions(userPermissions || defaultPermissions);
  }, [switchCofre, tenantToEdit]);

  const handleRoleChange = (event) => {
    const role = event.target.value;
    const newPermissions = rolesPermissions[role];
    // const newPermissions = rolesPermissions[role] || defaultPermissions;

    setPermissions(newPermissions);
    setNovoUsuario((prevState) => {
      const updatedTenants = switchCofre ? prevState.tenants : prevState.tenants.map((t) => (t.tenant === tenantId ? { ...t, permissions: newPermissions } : t));

      const updatedClients = switchCofre ? prevState.clients.map((c) => (c.client === tenantId ? { ...c, permissions: newPermissions.vault } : c)) : prevState.clients;

      return { ...prevState, tenants: updatedTenants, clients: updatedClients };
    });

    if (!edit) {
      setselectStatus(role !== 'none');
    }
  };

  const handlePermissionChange = (section, permType) => {
    const updatedPermissions = {
      ...permissions,
      [section]: {
        ...permissions[section],
        [permType]: !permissions[section][permType],
      },
    };

    setPermissions(updatedPermissions);
    setNovoUsuario((prevState) => {
      const updatedTenants = switchCofre ? prevState.tenants : prevState.tenants.map((t) => (t.tenant === tenantId ? { ...t, permissions: updatedPermissions } : t));

      const updatedClients = switchCofre ? prevState.clients.map((c) => (c.client === tenantId ? { ...c, permissions: updatedPermissions.vault } : c)) : prevState.clients;

      return { ...prevState, tenants: updatedTenants, clients: updatedClients };
    });
  };

  const name = tenant?.name || tenant?.tenant_name || nameTenantPermissions;

  return (
    <div className="h-full rounded-t-lg grid grid-rows-12 grid-cols-12">
      <h1 className="font-semibold col-span-12 flex items-center justify-center">Permissões para {name}</h1>
      <div className="row-start-2 row-span-11 col-span-12">
        <div className="bg-white row-start-2 row-span-10 col-span-12 text-gray-600 font-semibold">
          <div className="p-1">
            <FormControl size="small" fullWidth>
              <InputLabel>Modelo de permissão</InputLabel>
              {!switchCofre ? (
                <Select label="Modelo de permissão" size="small" onChange={handleRoleChange}>
                  <MenuItem value="group-admin">Administrador do grupo</MenuItem>
                  <MenuItem value="evidencer">Evidenciador</MenuItem>
                </Select>
              ) : (
                <Select label="Modelo de permissão" size="small" onChange={handleRoleChange}>
                  <MenuItem value="user">Usuário</MenuItem>
                  <MenuItem value="admin">Administrador</MenuItem>
                </Select>
              )}
            </FormControl>
          </div>
          {Object.keys(permissions).map((section) => (
            <PermissionRow key={section} section={section} permissions={permissions} onChange={handlePermissionChange} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PermissionsTab;
