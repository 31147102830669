/* eslint-disable react/prop-types */
import React from 'react';
import { MinusIcon } from '@heroicons/react/24/outline';
import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AxiosConfigs from '../../configs';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';

const ClientCreation = ({ client, setClientAdd }) => {
  const [clientData, setClientData] = useState({
    cnpj: '',
    fantasy_name: '',
    razao_social: '',
    ie: '',
    im: '',
    phone: '',
    zip_code: '',
    city: '',
    state: '',
    street: '',
    number: '',
    district: '',
    complement: '',
    emails: [''],
  });
  const [isLoading, setIsLoading] = useState(false);

  const getCnpjInfo = (cnpj) => {
    setIsLoading(true);
    axios
      .get(`https://publica.cnpj.ws/cnpj/${cnpj}`)
      .then((res) => {
        if (res.statusCode === 200) {
          const data = res.data.estabelecimento;
          setClientData({
            ...clientData,
            cnpj: cnpj,
            fantasy_name: data?.nome_fantasia ?? '',
            razao_social: res.data.razao_social,
            ie: data?.inscricoes_estaduais[0]?.inscricao_estadual ?? '',
            im: data?.inscricoes_estaduais[0]?.inscricao_municipal ?? '',
            phone: `${data.ddd1}${data.telefone1}`,
            zip_code: data?.cep ?? '',
            city: data?.cidade.nome ?? '',
            state: data?.estado.sigla ?? '',
            street: data?.logradouro ?? '',
            number: data?.numero ?? '',
            district: data?.bairro ?? '',
            complement: data?.complemento ?? '',
            emails: [data.email],
          });
        }
      })
      .catch((error) => {
        toast.error('Houve um erro ao buscar dados do CNPJ! ' + error.response.data.titulo);
        setClientData({
          ...clientData,
          // cnpj: '',
          fantasy_name: '',
          razao_social: '',
          ie: '',
          im: '',
          phone: '',
          zip_code: '',
          city: '',
          state: '',
          street: '',
          number: '',
          district: '',
          complement: '',
          emails: [''],
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEmails = (e, index) => {
    const newEmails = [...clientData.emails];
    newEmails[index] = e.target.value;
    setClientData({ ...clientData, emails: newEmails });
  };

  const handleAddEmail = () => {
    setClientData({ ...clientData, emails: [...clientData.emails, ''] });
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...clientData.emails];
    newEmails.splice(index, 1);
    setClientData({ ...clientData, emails: newEmails });
  };

  const createClient = () => {
    AxiosConfigs.post('/new_clients', clientData)
      .then((res) => {
        if (res.status === 201) {
          toast.success('Cliente cadastrado com sucesso!');
          setClientData({
            cnpj: '',
            fantasy_name: '',
            razao_social: '',
            ie: '',
            im: '',
            phone: '',
            zip_code: '',
            city: '',
            state: '',
            street: '',
            number: '',
            district: '',
            complement: '',
            emails: [''],
          });
          setClientAdd(false);
        }
      })
      .catch((err) => {
        toast.error(`Erro ao cadastrar cliente! ${err.response.data}`);
      });
  };

  const updateClient = () => {
    AxiosConfigs.post(`/new_clients/${clientData.id}`, clientData)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Cliente atualizado com sucesso!');
          setClientAdd(false);
        }
      })
      .catch((err) => {
        toast.error(`Erro ao atualizar cliente! ${err.response.data}`);
      });
  };

  useEffect(() => {
    setClientData({ ...clientData, ...client });
  }, [client]);

  return (
    <form
      className="bg-white p-5 shadow rounded-lg grid gap-5"
      onSubmit={(e) => {
        e.preventDefault();
        clientData.id ? updateClient() : createClient();
      }}
    >
      <Box className="flex gap-5">
        <div className="w-[220px] relative">
          <TextField
            label="CNPJ"
            variant="outlined"
            className="w-full"
            onBlur={(e) => {
              const replaced = e.target.value.replace(/[^0-9]/g, '');
              if (replaced.length === 14) {
                getCnpjInfo(replaced);
              }
            }}
            onInput={(e) =>
              setClientData({
                ...clientData,
                cnpj: e.target.value.replace(/[^0-9]/g, ''),
              })
            }
            required={true}
            value={
              clientData.cnpj && clientData.cnpj.length === 14
                ? clientData.cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
                : clientData.cnpj.length === 11
                  ? clientData.cnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                  : clientData.cnpj
            }
          />
          <div className="absolute top-2.5 right-3" hidden={!isLoading}>
            <CircularProgress size={20} />
          </div>
        </div>
        <TextField
          label="Razão Social"
          variant="outlined"
          className="w-5/12"
          onInput={(e) => setClientData({ ...clientData, razao_social: e.target.value })}
          value={clientData.razao_social}
        />
        <TextField
          label="Nome Fantasia"
          variant="outlined"
          className="w-5/12"
          onInput={(e) => setClientData({ ...clientData, fantasy_name: e.target.value })}
          value={clientData.fantasy_name}
        />
        {/* <TextField label="IE" variant="outlined" /> */}
      </Box>
      <Box className="flex gap-5">
        <TextField label="Inscrição Estadual" variant="outlined" className="w-3/12" onInput={(e) => setClientData({ ...clientData, ie: e.target.value })} value={clientData.ie} />
        <TextField label="Inscrição Municipal" variant="outlined" className="w-3/12" onInput={(e) => setClientData({ ...clientData, im: e.target.value })} value={clientData.im} />
        <TextField
          label="Telefone"
          variant="outlined"
          className="w-2/12"
          onInput={(e) => setClientData({ ...clientData, phone: e.target.value })}
          value={clientData.phone ? clientData.phone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3') : ''}
        />
      </Box>
      <Box className="flex gap-5">
        <TextField
          label="CEP"
          variant="outlined"
          className="w-2/12"
          onInput={(e) => setClientData({ ...clientData, zip_code: e.target.value })}
          value={clientData.zip_code ? clientData.zip_code.replace(/(\d{5})(\d{3})/, '$1-$2') : ''}
        />
        <TextField label="Endereço" variant="outlined" className="w-8/12" onInput={(e) => setClientData({ ...clientData, street: e.target.value })} value={clientData.street} />
        <TextField
          label="Número"
          variant="outlined"
          className="w-2/12"
          onInput={(e) =>
            setClientData({
              ...clientData,
              number: e.target.value.replace(/([^0-9])/, ''),
            })
          }
          value={clientData.number.replace(/([^0-9])/, '')}
        />
      </Box>
      <Box className="flex gap-5">
        <TextField label="Bairro" variant="outlined" className="w-7/12" onInput={(e) => setClientData({ ...clientData, district: e.target.value })} value={clientData.district} />
        <TextField
          label="Complemento"
          variant="outlined"
          className="w-5/12"
          onInput={(e) => setClientData({ ...clientData, complement: e.target.value })}
          value={clientData.complement}
        />
      </Box>
      <Box className="flex gap-5">
        <TextField label="Cidade" variant="outlined" className="w-6/12" onInput={(e) => setClientData({ ...clientData, city: e.target.value })} value={clientData.city} />
        <FormControl variant="outlined" className="w-6/12" size="small">
          <InputLabel>Estado</InputLabel>
          <Select value={clientData.state} onChange={(e) => setClientData({ ...clientData, state: e.target.value })} label="Estado">
            <MenuItem value="AC">Acre</MenuItem>
            <MenuItem value="AL">Alagoas</MenuItem>
            <MenuItem value="AP">Amapá</MenuItem>
            <MenuItem value="AM">Amazonas</MenuItem>
            <MenuItem value="BA">Bahia</MenuItem>
            <MenuItem value="CE">Ceará</MenuItem>
            <MenuItem value="DF">Distrito Federal</MenuItem>
            <MenuItem value="ES">Espírito Santo</MenuItem>
            <MenuItem value="GO">Goiás</MenuItem>
            <MenuItem value="MA">Maranhão</MenuItem>
            <MenuItem value="MT">Mato Grosso</MenuItem>
            <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
            <MenuItem value="MG">Minas Gerais</MenuItem>
            <MenuItem value="PA">Pará</MenuItem>
            <MenuItem value="PB">Paraíba</MenuItem>
            <MenuItem value="PR">Paraná</MenuItem>
            <MenuItem value="PE">Pernambuco</MenuItem>
            <MenuItem value="PI">Piauí</MenuItem>
            <MenuItem value="RJ">Rio de Janeiro</MenuItem>
            <MenuItem value="RN">Rio Grande do Norte</MenuItem>
            <MenuItem value="RS">Rio Grande do Sul</MenuItem>
            <MenuItem value="RO">Rondônia</MenuItem>
            <MenuItem value="RR">Roraima</MenuItem>
            <MenuItem value="SC">Santa Catarina</MenuItem>
            <MenuItem value="SP">São Paulo</MenuItem>
            <MenuItem value="SE">Sergipe</MenuItem>
            <MenuItem value="TO">Tocantins</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className="w-12/12 grid grid-cols-4 items-center gap-2">
        {clientData.emails.map((email, index) => (
          <Box key={index} className="flex items-center">
            <TextField key={index} label="E-mail" variant="outlined" type="email" value={email} onInput={(e) => handleEmails(e, index)} />
            <IconButton className="size-8" color="secondary" onClick={() => handleRemoveEmail(index)}>
              <MinusIcon className="size-4" />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Box className="flex gap-5 justify-between">
        <Button className="w-2/12" onClick={handleAddEmail} variant="outlined">
          Adicionar email
        </Button>
        <Box className="">
          {/* <Button variant="outlined" onClick={() => navigate("")}>
            Cancelar
          </Button> */}
          <LoadingButton loading={isLoading} variant="contained" type="submit" className="w-32">
            Salvar
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default ClientCreation;
