/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react';
// import { useCookies } from 'react-cookie'

const PermissoesContext = createContext();
export const PermissoesProvider = ({ children }) => {
  const [permissoes, setPermissoes] = useState({
    vault: { view: true, create: false, update: false, delete: false },
    config: { view: true, update: false },
    table: { view: true, create: false, update: false, delete: false },
    evidences: { view: false, create: false, update: false, delete: false },
    inventory: { view: false, create: false, update: false, delete: false },
    reports: { view: true, create: false, update: false, delete: false },
    users: { view: true, create: false, update: false, delete: false },
    registrations: {
      clients: {
        create: false,
        delete: false,
        read: false,
        update: false,
      },
      services: {
        create: false,
        delete: false,
        read: false,
        update: false,
      },
      invoices: {
        create: false,
        delete: false,
        read: false,
        update: false,
      },
    },
  });
  const atualizarPermissoes = (novasPermissoes) => {
    localStorage.setItem('permissoes', JSON.stringify(novasPermissoes));
  };
  useEffect(() => {
    const permissoesStorage = localStorage.getItem('permissoes');
    if (permissoesStorage) {
      setPermissoes(JSON.parse(permissoesStorage));
    }
  }, []);
  return <PermissoesContext.Provider value={{ permissoes, atualizarPermissoes }}>{children}</PermissoesContext.Provider>;
};

export default PermissoesContext;
//==============================
// User crate ok - users
// User edit ok - permissions                  ok
// User delete ok - users
//==============================
//evidencies view ok - evidences
//                                            ok
//evidencies create ok - evidences
//==============================
//invenrory view ok
//                                           ok
//invenrory create ok

//report create ok                             montando
